import React from 'react';

export default function Home(){
    return <>
        <div className = "centered">
        <img className= "ml-2 logo_image" src="logo_home.png" alt="logo" data-testid="logo-image"></img>

        </div>
    </>
}
